import {useQuery, useQueryClient} from '@tanstack/react-query';
import {Cart} from 'types/cart.types';

/* keys */
import {cartQueryKeys} from './queries.keys';

/*
 * This returns the "cache" cart
 * No api happens here, just a helper to read cache
 *
 * Note: getQueryData is not a promise based item, I use it with useQuery because I
 * wanted to have the option of having those helper vars (status, data) etc..
 * and use it with the "select".
 *
 * But its really not needed. We could just pass the select into the cart "all" useQuery, but having
 * a mental seperation of accessing "cache" prompted me to create this helper.
 * */

export default function useCartCache<T = Cart>(select?: $FixMe): T {
  const queryClient = useQueryClient();

  const {data} = useQuery({
    queryKey: cartQueryKeys.all,

    queryFn: async () => {
      const cart = await queryClient.getQueryData(cartQueryKeys.all);

      return cart;
    },
    select,
    initialData: {},
  });

  return data as T;
}
