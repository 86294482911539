import {createWithEqualityFn} from 'zustand/traditional';
import {immer} from 'zustand/middleware/immer';
/* ----------------------------------------------------------------------- */
/* --------------------- TYPES  ------------------------------------------ */
/* ----------------------------------------------------------------------- */
export type Chat = {
  showChatButton: boolean;
  unreadMessages: boolean;
  chatShown: boolean;
};

export type ChatState = {
  chat: Chat;
  renderDesktopAsMobile: boolean;
  actions: {
    setChatShow: (chatShown: boolean) => void;
    setNewChatMessage: () => void;
    setRenderDesktopAsMobile: (renderAsMobile: boolean) => void;
  };
};

/* ----------------------------------------------------------------------- */
/* ---------------------  CHAT STORE SETUP  ------------------------------ */
/* ----------------------------------------------------------------------- */

const useChatStore = createWithEqualityFn<ChatState>()(
  immer((set, get) => ({
    chat: {
      showChatButton: false,
      unreadMessages: false,
      chatShown: false,
    },
    renderDesktopAsMobile: false,
    /* Actions */
    actions: {
      setChatShow: show => {
        set(state => {
          const newChatObj = {
            chatShown: show,
            unreadMessages: show ? false : state.chat.unreadMessages,
          };

          state.chat = {...state.chat, ...newChatObj};
        });
      },
      setNewChatMessage: () => {
        set(state => {
          state.chat = {...state.chat, chatShown: state.chat?.chatShown ?? false};
        });
      },
      setRenderDesktopAsMobile: (renderAsMobile: boolean) => {
        set(state => {
          state.renderDesktopAsMobile = renderAsMobile;
        });
      },
    },
  })),
);

export const useChatStoreActions = () => useChatStore(state => state.actions);

export default useChatStore;
