import React, {useEffect, useRef, useState} from 'react';
import {Icon} from 'ht-styleguide';
import classNames from 'classnames';

/* utils */
import {noop} from '@utils/event';

/* styles */
import styles from './bar.module.scss';

/* Types */
import {STATUSES} from 'types/component.types';
import {NotificationSnack} from '@store/client/store.snack';

type CustomTemplateProps = {
  copyStyles: string;
  templateData: unknown;
};

type BarProps = Partial<
  NotificationSnack & {
    barStyle: string;
    className: string;
    barClassName: string;
    dismissableOnClick: BaseAnyFunction;
    template?: React.ComponentType<CustomTemplateProps>;
  }
>;

type ContentHandlerProps = Pick<BarProps, 'content' | 'template' | 'templateData'>;

const WRAPPER_BOTTOM_PAD = 8;
const BAR_TRANSITION_TIME = 1000;
const SUCCESS_REMOVAL_DELAY = 5000;

/**
 * ContentHandler is responsible for rendering the message inside the notification bar.
 * It either directly uses HTML content or utilizes a custom template based on the `template` prop.
 */
const ContentHandler = ({content, template, templateData}: ContentHandlerProps) => {
  const copyStyles = classNames('p1', styles.copy);
  let component = <p className={copyStyles} dangerouslySetInnerHTML={{__html: content || ''}} />;
  if (template) {
    const CustomTemplate = template;
    component = <CustomTemplate copyStyles={copyStyles} templateData={templateData} />;
  }
  return component;
};

const Bar = ({
  status = STATUSES.warning,
  content = '',
  template,
  templateData,
  dismissable,
  dismissableOnClick = noop,
  barStyle,
  autoClose = true,
  className,
  barClassName,
}: BarProps) => {
  if (!content && !template) return null;
  const barRef = useRef<HTMLDivElement>(null);

  const [wrapperMaxHeight, setWrapperMaxHeight] = useState(0);
  const [showBar, setShowBar] = useState(false);

  const handleDismiss = (e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    setWrapperMaxHeight(0);
    setShowBar(false);
    setTimeout(() => {
      dismissableOnClick();
    }, BAR_TRANSITION_TIME);
  };

  useEffect(() => {
    const barElem = barRef.current;
    if (!barElem) return;
    setWrapperMaxHeight(barElem.offsetHeight + WRAPPER_BOTTOM_PAD);
    setShowBar(true);
  }, []);

  useEffect(() => {
    if ([STATUSES.success, STATUSES.info].includes(status) && autoClose) {
      const timeoutId = setTimeout(() => {
        handleDismiss();
      }, SUCCESS_REMOVAL_DELAY);
      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, []);

  let iconName = 'v2-green-checkmark';
  if (status === 'warning') iconName = 'v2-neutral-yellow-circle';
  if (status === 'error') iconName = 'v2-red-circle-x';
  if (status === 'info') iconName = 'v2-info-icon-default';

  const barStyles = classNames(
    styles.bar,
    {
      [styles.success]: status === STATUSES.success,
      [styles.warning]: status === STATUSES.warning,
      [styles.error]: status === STATUSES.error,
      [styles.info]: status === STATUSES.info,
      [styles.showBar]: showBar,
    },
    barStyle,
    barClassName,
  );

  const wrapperInlineStyle = {maxHeight: `${wrapperMaxHeight}px`};

  return (
    <div style={wrapperInlineStyle} className={classNames(styles.barWrapper, className)}>
      <div className={styles.barPadding}>
        <div ref={barRef} className={barStyles}>
          <Icon name={iconName} className={styles.statusIcon} />
          <ContentHandler content={content} template={template} templateData={templateData} />
          {dismissable && (
            <button className="plainButton" onClick={handleDismiss} type="button">
              <Icon name="v2-close-icon" className={styles.closeIcon} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Bar;
