import React, {useCallback} from 'react';
import classNames from 'classnames';

/* App Snack Store */
import useSnackStore, {NotificationSnack, useSnackStoreActions} from '@store/client/store.snack';

/* Components */
import Bar from '../Bar';

/* Styles */
import styles from './fixed.module.scss';

const Fixed = () => {
  /* App Store: notifications */
  const notificationsSnack = useSnackStore(state => state.notificationsSnack);
  const {deleteAppSnackbarNotification} = useSnackStoreActions();

  /* Delete, currently, just removes all. */
  const dismiss = useCallback(
    (notice: NotificationSnack) => () => {
      deleteAppSnackbarNotification(notice);
    },
    [],
  );

  const outerWrapperStyles = classNames(styles.outerWrapper, {
    [styles.hide]: notificationsSnack.length === 0,
  });

  return (
    <div className={outerWrapperStyles}>
      <div className={styles.innerWrapper}>
        {notificationsSnack.map(notice => {
          const dismissHandler = dismiss(notice);
          return (
            <Bar
              key={notice.id}
              status={notice.status}
              content={notice.content}
              template={notice.template}
              templateData={notice.templateData}
              dismissable={notice.dismissable}
              dismissableOnClick={dismissHandler}
              autoClose={notice.autoClose}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Fixed;
