import React, { memo, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';

/* Components */
import HTToastContainer from '@components/UI/Toast/HTToastContainer';

/* Constants */
import { TOAST_CONTAINER_ID } from '@components/UI/Toast/constants';
import { PINNED_CONTAINER_ID, PINNED_FOOTER_ID } from './constants';

/* Styles */
import styles from './pinnedcontainer.module.scss';

const COUNTER = 8;
const TIME_INTERVAL = 500;

const PinnedContainer = () => {
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef<HTMLDivElement>(null);
  const intervalID = useRef<ReturnType<typeof setInterval> | null>(null);
  const counter = useRef(COUNTER);

  const clearFooterCheckInterval = () => {
    if (intervalID.current) {
      clearInterval(intervalID.current);
    }
    intervalID.current = null;
  };

  /**
     The following will check the height of the footer in intervals.
  */
  const checkPinnedFooterHeight = () => {
    if (intervalID.current) {
      counter.current = COUNTER;
    } else {
      intervalID.current = setInterval(() => {
        if (footerRef.current) {
          setFooterHeight(footerRef.current.getBoundingClientRect().height);
        }
        if (!counter.current) {
          clearFooterCheckInterval();
          counter.current = COUNTER;
        } else {
          counter.current -= 1;
        }
      }, TIME_INTERVAL);
    }
  };

  useEffect(() => {
    const unsubscribe = toast.onChange((_, containerId) => {
      if (containerId === TOAST_CONTAINER_ID && footerRef.current) {
        checkPinnedFooterHeight();
      }
    });

    return () => {
      unsubscribe();
      clearFooterCheckInterval();
    };
  }, []);

  return (
    <>
      <HTToastContainer className="container_pinned" />
      <div className={cn(styles.pinnedFooter)} id={PINNED_CONTAINER_ID}>
        <div id={PINNED_FOOTER_ID} ref={footerRef} />
      </div>
      <style jsx>{`
           .container_pinned {
             bottom: ${footerHeight}px
           }`}
      </style>
    </>
  );
};

export default memo(PinnedContainer);
