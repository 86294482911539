import {useEffect, useRef} from 'react';
import {useRouter} from 'next/router';

/* Stores */
import {useResponsiveDeviceType} from '@store/client/store.responsive';
import useKustomerChatStore from '@store/client/store.chat';

/* Store Contexts */
import useUserCache from '@store/queries/user/useUser.cache';
import useCartCache from '@store/queries/cart/useCart.cache';

/* Class */
import HTKustomerChat from '@components/Kustomer/Kustomer.methods';

/* Utils */
import {noop} from '@utils/event';
import {browserInfo, isMobileOrTablet} from '@utils/ui';
import {sleep} from '@utils/helpers';

import {BaseLayoutProps} from '@components/PageLayouts/BaseLayoutContent/Layout.Base';
import {PAGE_TYPE_ADD_SKU, PAGE_TYPE_HOME} from '@constants/pages';

/*
 * We could just copy from client, but the issue here is we need to initialize the download after user
 * interaction. So, we need listeners to facilitate this.
 *
 * params: hideChat - this boolean is passed on the static generated page in the getLayout
 * */
type KustomerChatProps = {
  hideChat?: boolean;
  pageConfig?: BaseLayoutProps['pageConfig'];
};

declare const window: any;

const KustomerComponent = (props: KustomerChatProps) => {
  /* Destructure props */
  const {hideChat = false, pageConfig} = props;

  /* Hooks */
  const {query = {}, asPath} = useRouter();
  const enqueueRef = useRef<BaseAnyFunction[]>([]);
  const user = useUserCache();
  const cart = useCartCache();
  const browser = browserInfo();

  /* Client State */
  const deviceType = useResponsiveDeviceType();
  const mobileOrTablet = isMobileOrTablet();

  /* Const */
  const isProductPage = Boolean(query.productSeoName);
  /* IF we fall within a mobile overrride */
  const isOverrideMobileCondition = [mobileOrTablet && [PAGE_TYPE_ADD_SKU, PAGE_TYPE_HOME].includes(pageConfig?.page || '')].some(Boolean);
  const overrideToShowChatOnload = () => {
    if (!mobileOrTablet) return false;

    if (isOverrideMobileCondition) return false;

    return true;
  };

  /* Methods */
  const loadEnqueue = () => {
    /* If we qued any methods fire them */
    enqueueRef.current.forEach(m => m());
  };

  const loadUser = () => {
    if (user?.kustomerJwt) {
      window?.Kustomer?.login({
        jwtToken: user.kustomerJwt,
      });
    }
  };
  /*
   * Script is loaded so lets initialize the start/and pertinent info
   * */
  const initializeStart = () => {
    HTKustomerChat.start({
      hideChatIconOverride: overrideToShowChatOnload(),
      callBack: () => {
        loadUser();
        loadEnqueue();

        /* Initial listener. Subsequent starts shouldn't need this */
        window.Kustomer.addListener('onConversationCreate', (res: {conversationId: string}) => {
          window.Kustomer.describeConversation({
            conversationId: res.conversationId,
            customAttributes: {
              chatDeviceTypeStr: deviceType,
              chatBrowserTypeStr: browser.name,
              chatOriginUrlStr: window.location.href,
              /* we might not need this here. lets see how product page fleshes out */
              ...(isProductPage ? {chatTeamIntentStr: 'Product Questions'} : {}),
            },
          });
        });
      },
    });
  };

  /*
   * Lets load the kustomer chat script
   * */
  const loadKustomer =
    (predicate: () => boolean = () => true) =>
    () => {
      if (!('Kustomer' in window) && predicate()) {
        const script = document.createElement('script');
        script.defer = true;
        script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
        script.setAttribute('data-kustomer-api-key', `${process.env.NEXT_PUBLIC_KUSTOMER_API_KEY}`);

        window.document.body.appendChild(script);
      }
    };

  const renderDesktopAsMobile = useKustomerChatStore(state => state.renderDesktopAsMobile);
  const addAndRemoveListeners = (type: string) => {
    window[`${type}EventListener`]('kustomerLoaded', initializeStart);
    window[`${type}EventListener`](
      renderDesktopAsMobile ? 'initializeKustomerMobileLoad' : 'mouseover',
      loadKustomer(() => !mobileOrTablet),
      {once: true},
    );
    window[`${type}EventListener`](
      'initializeKustomerMobileLoad',
      loadKustomer(() => mobileOrTablet),
      {once: true},
    );
  };

  /*
   * Set up and remove listeners
   * */
  useEffect(() => {
    if (!hideChat) {
      addAndRemoveListeners('add');

      /*
        Since we we want to wait till the session is loaded, we'll just do this here.
        We know the user wants to explicitly have the chat opened, so we load it, then open it.
      */
      if (query.chat) {
        /* this prevents closure issues */
        enqueueRef.current = [...enqueueRef.current, () => window.Kustomer.open()];
        loadKustomer()();
      }

      /* IF we fall within a mobile overrride */
      if (!query.chat && isOverrideMobileCondition) {
        sleep(2000).then(() => {
          window.dispatchEvent(new CustomEvent('initializeKustomerMobileLoad'));
        });
      }

      /* Remove event listeners */
      return () => addAndRemoveListeners('remove');
    }

    return noop;
  }, [cart, hideChat, asPath]);

  useEffect(() => {
    if (hideChat) {
      // close chat
    }
  }, [hideChat]);

  return null;
};

export default KustomerComponent;
