import React from 'react';
import {ToastContainer, toast, cssTransition} from 'react-toastify';
import cn from 'classnames';
import {Icon} from 'ht-styleguide';

/* Utils */
import {noop} from '@utils/event';

/* Constants */
import {TOAST_CONTAINER_ID} from '../constants';

/* Styles */
import styles from './toastcontainer.module.scss';

// Slide Transition that closely resembles what the Design team spec'd out
const CustomTransition = cssTransition({
  enter: styles.animateUp,
  exit: styles.animateDown,
});

/* Types */
type HTToastContainerProps = {
  className: string;
  closeToast?: BaseAnyFunction;
};

type CloseButtonProps = {
  closeToast: BaseAnyFunction;
};

// Close Button
const CloseButton = ({closeToast}: CloseButtonProps) => (
  <button onClick={closeToast} className="plainButton" type="button">
    <Icon name="v2-close-icon" className={cn('marginLeft-small', styles.closeButton)} />
  </button>
);

// Toast Container
const HTToastContainer = (props: HTToastContainerProps) => {
  const {className = '', closeToast = noop, ...restProps} = props;

  return (
    <ToastContainer
      autoClose={4000}
      closeButton={<CloseButton closeToast={closeToast} />}
      containerId={TOAST_CONTAINER_ID}
      draggable={false}
      enableMultiContainer
      hideProgressBar
      newestOnTop
      position={toast.POSITION.BOTTOM_CENTER}
      transition={CustomTransition}
      className={cn('marginBottom-medium', styles.toastContainer, className)}
      toastClassName={cn('marginTop-small', styles.toast)}
      bodyClassName={styles.toastBody}
      {...restProps}
    />
  );
};

export default HTToastContainer;
