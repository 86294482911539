/* next 'page' level site wide constants */

/* ------------------------------------------------- */
/* ---------------- PAGE TYPES --------------------- */
/* ------------------------------------------------- */
export const PAGE_TYPE_SERVICES = 'sku_service';
export const PAGE_TYPE_PRODUCTS = 'sku_product';
export const PAGE_TYPE_SERVICES_GROUP = 'servicesGroup';
export const PAGE_TYPE_ADD_SKU = 'addsku';
export const PAGE_TYPE_PLANS = 'plans';
export const PAGE_TYPE_LOCATION = 'location';
export const PAGE_TYPE_SPEED = 'speed';
export const PAGE_TYPE_HOME = 'home';
export const PAGE_TYPE_CATEGORY = 'category';
export const PAGE_TYPE_TERMS_OF_SERVICE = 'termsOfService';
export const PAGE_TYPE_SAFETY_AND_SUPPORT = 'safetyAndSupport';
export const PAGE_TYPE_LOGIN = 'login';
export const PAGE_TYPE_FAQ = 'faq';
export const PAGE_TYPE_REGISTER = 'register';
export const PAGE_TYPE_UPDATE_PASSWORD = 'updatePassword';
export const PAGE_TYPE_RESET_PASSWORD = 'resetPassword';
export const PAGE_TYPE_CANCELLATION_POLICY = 'cancellationPolicy';
export const PAGE_TYPE_CUSTOMER_REVIEWS = 'customerReviews';
export const PAGE_TYPE_MEET_OUR_TECHS = 'meetOurTechs';
export const PAGE_TYPE_HOME_HUB_SURVEYS = 'homeHubSurveys';
export const PAGE_TYPE_USER_HUB_SURVEYS = 'userHomeHub';
export const PAGE_TYPE_HOME_HUB_SURVEY_RECOMMENDATIONS = 'homeHubSurveyRecommendations';
export const PAGE_TYPE_IFRAME_PARTNER = 'iframePartner';
export const PAGE_TYPE_ALL_SERVICES = 'Services';
export const PAGE_TYPE_LANDING = 'landing';
export const PAGE_TYPE_COOKIES = 'cookies';
export const PAGE_TYPE_MEET_THE_TECHS = 'meetTheTechs';
